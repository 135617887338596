<template>
  <!--引导页-->
  <div>
    <div class="content">
    <!--姓名-->
    <div class="personal-info">
      <div>
        <span class="name-tip">姓名</span>
        <span class="bitian">
             必填
        </span>
      </div>
      <div class="name-content">
        <span>张无忌</span>
        <span style="margin-left: 10px;">男</span>
        <span style="margin-left: 10px;">18868698965</span>
        <van-icon color="#B9BCC1" size="20"  name="arrow" class="float-right" @click="go"/>
      </div>
    </div>
    <!--归属地-->
    <div class="addres">
      <span>归属地</span>
      <div>
        <span>山东</span>
        <span style="margin-left: 10px;">济南</span>
        <span style="margin-left: 10px;">济南</span>
        <van-icon color="#B9BCC1" size="20"  name="arrow" class="float-right"/>
      </div>
    </div>
    <!--选择工种-->
    <div class="select-box">
      <span>选择工种</span>
      <div class="select-content">
        <span v-for="(item,index) in workTypes" :key="index">
          <van-button plain hairline round type="info" size="small" class="option">{{item}}</van-button>
        </span>
      </div>
      <div class="info-tip">
        <span>提示:请完成以上信息,以便快速匹配到您的所需</span>
      </div>
    </div>
    </div>
    <div class="footer">
      <div  class="float-right skip">跳过<van-icon color="#B9BCC1 " size="16"  name="arrow"/></div>
      <van-button round type="info" block >确定</van-button>
    </div>

  </div>
</template>


<script>

export default {
  components: {
  },
  data() {
    return {
      index: 0,
      name:undefined,
      sex:undefined,
      phone:undefined,
      workTypes:undefined
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(){
      this.workTypes = ["包工头","标志制作员","投标战略","谈判负责人","洒水车","挖掘机","推土车","其他"]
    },
    go(){

    }
    
  },
};
</script>

<style lang="less" scoped>
  body{
    background: #FFFFFF;
  }
  .content{
    min-height: calc(100vh - 160px);
  }
  .personal-info{
    border-bottom: 1px #EFEFEF solid;
    height: 90px;
    margin-top: 20px;
    margin-left: 15px;
    .name-tip{
      font-size: 16px;font-weight: 600;
    }
    .bitian{
      color: #F38003;height: 30px;border: 1px #F38003 solid;font-size: 10px;margin-left: 10px;border-radius: 4px;padding: 2px
    }
    .name-content{
      margin-top: 10px;
    }
  }
  .addres{
    border-bottom: 1px #EFEFEF solid;
    height: 90px;
    margin-top: 10px;
    margin-left: 15px;
    >span{
      font-size: 15px;font-weight: 600;
    }
    div{
      margin-top: 10px;
    }
  }
  .select-box{
    border-bottom: 1px #EFEFEF solid;
    margin-top: 10px;
    margin-left: 15px;
    >span{
      font-size: 15px;font-weight: 600;
    }
    .select-content{
      margin-top: 10px;
      >span{
        margin-top: 10px;
      }
      .option{
        width: 100px;margin: 5px;
      }
    }
    .info-tip{
      margin: 10px 0;color: #8B8D90;font-size: smaller;
    }
  }

  .float-right{
    float: right;
  }
  .skip{
    color: #B9BCC1;font-size: 16px;margin-bottom: 10px;margin-right: 10px;
  }
  .footer{
    height: 50px;
  }

</style>
